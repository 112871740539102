import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Layout, Hero, Breadcrumbs, ArticleSummary } from '../components'
import { featuredImgProps } from '../proptypes'
import { removeTrailingSlash, getValidDates } from '../utils'

const ArchiveTemplate = ({ hero, featuredImage, slug, articles }) => (
  <div
    id="content"
    className={`content-archive content-archive-${slug
      .split('/')
      .filter((i) => i)
      .join('-')}`}
  >
    <Breadcrumbs slug={slug} title={hero.header} />
    <Hero {...hero} featuredImage={featuredImage} />
    {!!articles && articles.length && (
      <section id="inner-content">
        <div className="category-summary">
          {articles.map(
            ({
              excerpt,
              validDates: { date },
              fields: { slug, imageBlog },
              frontmatter: { title, featuredImage },
            }) => (
              <ArticleSummary
                key={uuidv4()}
                slug={removeTrailingSlash(slug)}
                title={title}
                src={imageBlog}
                alt={
                  !!featuredImage && !!featuredImage.alt
                    ? featuredImage.alt
                    : null
                }
                date={date}
                excerpt={excerpt}
              />
            ),
          )}
        </div>
      </section>
    )}
  </div>
)

ArchiveTemplate.propTypes = {
  hero: PropTypes.shape({
    header: PropTypes.string,
    subheader_MD: PropTypes.string,
  }),
  featuredImage: featuredImgProps,
  slug: PropTypes.string.isRequired,
  articles: PropTypes.array,
}

const Archive = ({ data }) => {
  const {
    frontmatter: { hero, featuredImage },
    fields: { slug, imageMobile, imageTablet, imageDesktop },
  } = data.markdownRemark
  if (featuredImage) {
    featuredImage.mobile = imageMobile
    featuredImage.tablet = imageTablet
    featuredImage.desktop = imageDesktop
  }
  const { location } = data.site.siteMetadata
  location.slug = removeTrailingSlash(slug)
  const children = [
    ...data.children.edges.map(({ node }) => ({
      ...node,
    })),
    ...data.related.edges.map(({ node }) => ({
      ...node,
    })),
  ]
  const uniqueChildren = _.uniqBy(children, 'fields.slug').map((article) => {
    const {
      fields: { gitAuthorTime, gitCreatedTime },
      frontmatter: { date },
    } = article
    const validDates = getValidDates(date, gitAuthorTime, gitCreatedTime)
    article.validDates = validDates
    return article
  })
  const archiveProps = {
    hero,
    featuredImage,
    slug,
    articles: uniqueChildren,
  }
  return (
    <Layout location={location} data={data}>
      <ArchiveTemplate {...archiveProps} />
    </Layout>
  )
}

Archive.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Archive

export const ArchiveQuery = graphql`
  query ArchiveTemplate(
    $id: String!
    $category: String!
    $parentPath: String!
    $childRegex: String!
  ) {
    site {
      ...siteMeta
    }
    parent: markdownRemark(fields: { slug: { eq: $parentPath } }) {
      fields {
        slug
      }

      frontmatter {
        hero {
          header
        }
      }
    }
    children: allMarkdownRemark(
      filter: { fields: { slug: { regex: $childRegex } } }
    ) {
      edges {
        node {
          fields {
            slug
            gitAuthorTime
            gitCreatedTime
            imageBlog {
              childImageSharp {
                fluid(maxWidth: 420, maxHeight: 420, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          excerpt(format: PLAIN, pruneLength: 250)
          frontmatter {
            title
            date
            featuredImage {
              alt
            }
          }
        }
      }
    }
    related: allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: $category } } }
    ) {
      edges {
        node {
          fields {
            slug
            gitAuthorTime
            gitCreatedTime
            imageBlog {
              childImageSharp {
                fluid(maxWidth: 420, maxHeight: 420, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          excerpt(format: PLAIN, pruneLength: 250)
          frontmatter {
            title
            date
            featuredImage {
              alt
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
        gitAuthorTime
        gitCreatedTime
        imageMobile {
          ...mobileHeroImage
        }
        imageTablet {
          ...tabletHeroImage
        }
        imageDesktop {
          ...desktopHeroImage
        }
      }
      frontmatter {
        ...seoFields
        ...heroFields
        ...featuredImageFields
        templateKey
      }
    }
  }
`
